<template>
    <div class="form-box">
        <el-form-item :label="$t('sales.order.labels.contact')" prop="sender.contact_id">
            <el-select
                v-model="senderContactId"
                default-first-option
                filterable
                class="fullWidth"
                :no-data-text="$t('sales.order.awb.no-contacts')"
            >
                <el-option value="new" label="--- Adauga persoana de contact noua ---"></el-option>
                <el-option
                    v-for="(contact, index) in contacts"
                    :key="index"
                    :value="contact.id"
                    :label="contact.name + ', ' + contact.phone"
                ></el-option>
            </el-select>
        </el-form-item>

        <template v-if="senderContactId === 'new' && !enableLocker">
            <el-form-item :label="$t('sales.order.labels.contact-name')" prop="sender.contact">
                <el-input v-model="senderContact"></el-input>
            </el-form-item>

            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item :label="$t('sales.order.labels.phone')" prop="sender.phone" required>
                        <vue-tel-input
                            @validate="onPhoneValidate"
                            defaultCountry="ro"
                            :inputOptions="{ showDialCode: true, tabindex: 0 }"
                        ></vue-tel-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('sales.order.labels.email')" prop="sender.email">
                        <el-input v-model="senderEmail"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

        <template v-if="currentCourier.uid !== 'fancourier'">
            <el-form-item :label="$t('sales.order.labels.address')" prop="sender.address_id">
                <el-select
                    v-model="senderAddressId"
                    default-first-option
                    filterable
                    class="fullWidth"
                    :no-data-text="$t('sales.order.awb.no-addresses')"
                >
                    <el-option value="new" label="--- Adauga o adresa noua ---"></el-option>
                    <el-option
                        v-for="(address, index) in addresses"
                        :key="index"
                        :value="address.id"
                        :label="getAddressLabel(address)"
                    ></el-option>
                </el-select>
            </el-form-item>

            <template v-if="senderAddressId === 'new'">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item :label="$t('sales.order.labels.county')" prop="sender.county">
                            <el-select v-model="senderCounty" filterable class="fullWidth">
                                <el-option
                                    v-for="county in counties"
                                    :key="county"
                                    :label="county"
                                    :value="county"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('sales.order.labels.city')" prop="sender.city">
                            <el-select v-model="senderCity" filterable class="fullWidth">
                                <el-option
                                    v-for="(city, index) in senderCities"
                                    :key="index"
                                    :label="city"
                                    :value="city"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item :label="$t('sales.order.labels.address')" prop="sender.address">
                    <el-input v-model="senderAddress"></el-input>
                </el-form-item>

                <el-form-item :label="$t('sales.order.labels.zip')" prop="sender.zip">
                    <el-input v-model="senderZip"></el-input>
                </el-form-item>
            </template>
        </template>

        <Box v-if="currentCourier.uid == 'fancourier' && !enableLocker" type="info" :active="true">
            <div slot="box-body">
                <ul class="rawUl">
                    <li>
                        Adresa corespunde punctului de lucru selectat. Acesta a fost setat in sistemele curierului la
                        semnarea contractului. Pentru modificari trebuie sa contactati curierul.
                    </li>
                </ul>
            </div>
        </Box>

        <template v-if="courierHasLockerActive && courierAccountHasLockerActive && canDropOffToLocker">
            <div class="enable-checkbox">
                <el-checkbox v-model="enableLocker">Incarca produsele la locker</el-checkbox>
            </div>
            <Locker v-if="enableLocker" @lockerChanged="onLockerChanged"></Locker>
        </template>
    </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import { VueTelInput } from 'vue-tel-input';
const Locker = () => import('./Locker.vue');

export default {
    components: {
        VueTelInput,
        Box: () => import('vue-info-box-element'),
        Locker,
    },
    data() {
        return {
            selectedLocker: null,
        };
    },
    computed: {
        couriers: sync('sales/order@awb.couriers'),
        addresses: get('sales/order@awb.addresses'),
        contacts: get('sales/order@awb.contacts'),
        counties: get('sales/order@awb.counties'),
        senderCities: sync('sales/order@awb.senderCities'),
        form: sync('sales/order@awb.form'),
        ...sync('sales/order@awb.form', {
            formCourierId: 'courier_id',
            courierAccountId: 'courier_account',
        }),
        ...sync('sales/order@awb.form.sender', {
            senderAddressId: 'address_id',
            senderContactId: 'contact_id',
            senderContact: 'contact',
            senderPhone: 'phone',
            senderEmail: 'email',
            senderCounty: 'county',
            senderCity: 'city',
            senderAddress: 'address',
            senderZip: 'zip',
            lockerLoadingId: 'locker_loading_id',
            enableLocker: 'enable_locker',
        }),
        currentCourier() {
            if (this.formCourierId) {
                return this.couriers[this.formCourierId];
            }
            return false;
        },
        canDropOffToLocker() {
            return this.currentCourier?.lockerLoadingAvailable == 1;
        },
        courierHasLockerActive() {
            if (!this.currentCourier) {
                return false;
            }
            return this.currentCourier?.hasLockers == 1;
        },
        courierAccountHasLockerActive() {
            if (!this.currentCourier || !this.courierAccountId) {
                return false;
            }
            return this.currentCourier?.accounts?.[this.courierAccountId]?.enableLockers == 1;
        },
    },
    methods: {
        getCities: call('app/GetCitiesList'),
        changeCounty(clear) {
            this.senderCities = [];

            if (clear) {
                this.senderCity = '';
            }

            this.getCities({
                county: this.senderCounty,
            })
                .then((res) => {
                    this.senderCities = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCities',
                            params: {
                                county: this.senderCounty,
                            },
                        },
                    });
                });
        },
        getAddressLabel(address) {
            let result = address.address + ', ' + address.city + ', ' + address.county;
            if (address.zip !== '') {
                result += ', ' + address.zip;
            }
            return result;
        },
        onPhoneValidate(data) {
            if (data.valid) {
                this.senderPhone = data.number;
            } else {
                this.senderPhone = '';
            }
        },
        onLockerChanged({ id, locker }) {
            this.lockerLoadingId = id;
            this.selectedLocker = locker || null;
        },
    },
    watch: {
        senderCounty(value, oldValue) {
            if (oldValue !== '') {
                this.changeCounty(true);
            } else {
                this.changeCounty();
            }
        },
        // Reset locker loading container on courier change
        formCourierId() {
            this.enableLocker = false;
        },
        enableLocker(val) {
            if (!val) {
                this.lockerLoadingId = 0;
                this.selectedLocker = null;
            }
        },
    },
    created() {},
};
</script>
<style scoped></style>
